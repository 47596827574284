<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="$router.go(-1)"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center">THANH TOÁN BÁN VÉ</div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                            /></div
                    ></slot>
                </div>
            </ion-toolbar>
        </ion-header>
        <ion-content :scroll-events="false">
            <div class="column">
                <div class="grow">
                    <div class="container-md" style="padding-bottom: 80px">
                        <DxValidationGroup ref="formValidation">
                            <div class="mb-3">
                                <span class="font-12">Số lượng vé</span>
                                <DxNumberBox
                                    class="mb-2"
                                    v-model="model.SoLuong"
                                    validationMessageMode="always"
                                    ref="refSoLuong"
                                    :tabIndex="1"
                                    format=",##0"
                                    :min="0"
                                    styling-mode="underlined"
                                    valueChangeEvent="keyup"
                                >
                                    <DxValidator ref="refValidateSoLuong">
                                        <DxNumericRule message="Số lượng phải là số!" />
                                        <DxRangeRule
                                            :min="1"
                                            :message="`Số lượng không được nhỏ hơn 1!`"
                                        />
                                        <DxRangeRule
                                            :max="maxVe"
                                            :message="`Số lượng không được vượt quá ${maxVe}!`"
                                        />
                                    </DxValidator>
                                </DxNumberBox>
                            </div>
                            <div
                                :class="isValidSoDienThoai ? '' : 'mb-5'"
                                style="position: relative"
                            >
                                <span class="font-12">Điện thoại</span>
                                <DxTextBox
                                    class="mb-2"
                                    v-model="model.SoDienThoai"
                                    validationMessageMode="always"
                                    mode="tel"
                                    :tabIndex="2"
                                    ref="refTab1"
                                    styling-mode="underlined"
                                    :onEnterKey="onEnterKey"
                                    :onKeyUp="onKeyUpSoDienThoai"
                                    :onFocusOut="onFocusOutSoDienThoai"
                                    valueChangeEvent="keyup"
                                >
                                    <DxValidator ref="refValidateSoDienThoai">
                                        <DxStringLengthRule
                                            :max="13"
                                            message="Số điện thoại không được vượt quá 13 ký tự!"
                                        />
                                        <DxNumericRule
                                            message="Số điện thoại phải là số!"
                                        />
                                        <DxRangeRule
                                            :min="0"
                                            message="Số điện thoại phải là số!"
                                        />
                                        <DxPatternRule
                                            :pattern="phonePattern"
                                            message="Vui lòng nhập đúng định dạng số điện thoại để tiếp tục mua vé!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                                <div style="position: absolute; right: 0; top: 5px">
                                    <ion-button
                                        id="btn-qr"
                                        fill="clear"
                                        size="large"
                                        @click="quetMaQR()"
                                    >
                                        <ion-icon
                                            :icon="qrCodeOutline"
                                            class="font-24"
                                            style="color: black"
                                        ></ion-icon>
                                    </ion-button>
                                </div>
                            </div>
                            <div
                                class="font-12 font-italic disabled--text mb-3"
                                v-if="isValidSoDienThoai"
                            >
                                Điền đúng số điện thoại để truy vết phòng chống dịch
                                Covid-19
                            </div>
                            <div class="font-12 text-xs-left">Điểm xuống</div>
                            <DxSelectBox
                                v-model="model.DiemXuong"
                                :items="data.DiemXuong"
                                display-expr="tenDiemXuong"
                                :onEnterKey="onEnterKey"
                                value-expr="guidDiemXuong"
                                ref="refTab2"
                                :tabIndex="3"
                                :search-enabled="true"
                                styling-mode="underlined"
                                no-data-text="Không có dữ liệu!"
                                :onItemClick="onItemClickDiemXuong"
                                validationMessageMode="always"
                            >
                                <DxValidator ref="refDiemXuong">
                                    <DxRequiredRule
                                        message="Điểm xuống không được bỏ trống!"
                                    />
                                </DxValidator>
                            </DxSelectBox>

                            <GoiYVue
                                class="pt-4"
                                :data="GoiYDiemXuong"
                                :key="GoiYDiemXuong"
                                @ChonGoiY="ChonGoiYDiemXuong"
                            />
                            <div class="mb-3">
                                <span class="font-12">Giá vé (*)</span>
                                <DxNumberBox
                                    v-model="model.GiaVe"
                                    :show-spin-buttons="false"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    format=",##0đ"
                                    :onEnterKey="onEnterKey"
                                    ref="refTab3"
                                    :min="0"
                                    :step="1000"
                                    :tabIndex="4"
                                    valueChangeEvent="keyup"
                                >
                                    <!-- :onKeyUp="onChangeGiaVe" -->
                                    <DxValidator>
                                        <DxRequiredRule message="Vui lòng nhập giá vé!" />
                                        <DxRangeRule
                                            :max="2147483647"
                                            message="Giá vé không được vượt quá 2,147,483,647!"
                                        />
                                    </DxValidator>
                                </DxNumberBox>
                            </div>
                            <GoiYVue
                                class="pt-2"
                                :data="GoiYGiaVe"
                                :key="GoiYGiaVe"
                                @ChonGoiY="chonGoiYGiaVe"
                            />
                            <div
                                :class="isValidGiaVe ? '' : 'mb-4'"
                                v-if="
                                    ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                        $t(
                                            'MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru',
                                        )
                                    ] == 'true'
                                "
                            >
                                <span class="font-12">Giá vé sau giảm trừ (*)</span>
                                <DxNumberBox
                                    v-model="model.GiaVeSauGiamTru"
                                    :show-spin-buttons="false"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    format=",##0đ"
                                    :onEnterKey="onEnterKey"
                                    ref="refTab4"
                                    :step="1000"
                                    :min="0"
                                    :onFocusOut="onFocusOutGiaVe"
                                    :tabIndex="3"
                                    valueChangeEvent="keyup"
                                >
                                    <!-- :onKeyUp="onChangeGiaVe" -->
                                    <DxValidator ref="refValidateGiaVe">
                                        <DxRequiredRule message="Vui lòng nhập giá vé!" />
                                        <DxRangeRule
                                            :max="model.GiaVe"
                                            message="Không được vượt quá giá vé gốc!"
                                        />
                                        <DxRangeRule
                                            :max="2147483647"
                                            message="Giá vé không được vượt quá 2,147,483,647!"
                                        />
                                    </DxValidator>
                                </DxNumberBox>
                            </div>
                            <div class="row align-center mt-4">
                                <ion-checkbox
                                    v-model="CheckPhatHanhVe"
                                    :disabled="!quyenTuyChonPhatHanhVe"
                                ></ion-checkbox>
                                <span class="font-16 ml-2">Phát hành</span>
                                <span class="mr-3"></span>
                                <ion-checkbox v-model="CheckInVe"> </ion-checkbox>
                                <span class="font-16 ml-2">In vé</span>
                            </div>
                        </DxValidationGroup>
                        <ViDienTuVue
                            @ChonVi="
                                (item) => {
                                    model.Vi = item;
                                }
                            "
                            :GetData="LayDanhSachVi"
                        />
                    </div>
                </div>
                <div
                    class="container-xs fluid"
                    style="
                        border-top: 1px solid rgb(218, 220, 224);
                        background: #fff;
                        position: fixed;
                        bottom: 0;
                        width: 100vw;
                        z-index: 9;
                    "
                >
                    <div class="row justify-end align-center">
                        <ion-button
                            :color="
                                !model.SoLuong ||
                                !model.Vi ||
                                !model.GiaVe ||
                                (ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                    $t(
                                        'MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru',
                                    )
                                ] == 'true'
                                    ? !model.GiaVeSauGiamTru
                                    : false) ||
                                !model.DiemXuong ||
                                disabledXacNhan
                                    ? 'dark'
                                    : 'primary'
                            "
                            fill="solid"
                            expand="block"
                            @click="thuTien"
                            :disabled="
                                !model.Vi ||
                                !model.GiaVe ||
                                (ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                    $t(
                                        'MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru',
                                    )
                                ] == 'true'
                                    ? !model.GiaVeSauGiamTru
                                    : false) ||
                                !model.DiemXuong ||
                                !model.SoLuong ||
                                disabledXacNhan
                            "
                        >
                            Bán vé
                        </ion-button>
                    </div>
                </div>
            </div>
            <!-- ====================================Popup Xác nhận thanh toán ========================================= -->
            <!-- <PopupVue
            height="calc(100vh - 44px)"
            :dialog="dialogXacNhanThanhToan"
            title="Xác nhận thanh toán"
            buttonTextLeft="Hủy"
            @close="dongXacNhanThanhToan"
        >
            <template #content
                ><XacNhanThanhToanVue
                    :data="thongTinXacNhanThanhToan"
                    :dialog="dialogXacNhanThanhToan"
                />
            </template>
        </PopupVue> -->
            <!-- ====================================Popup Thông báo ========================================= -->
            <PopupVue
                height="260px"
                v-model:dialog="dialogThongBao"
                title=""
                :useButtonLeft="false"
                :fullWidth="false"
                @close="() => (dialogThongBao = false)"
            >
                <template #content>
                    <ThongBaoVue
                        :message="ParamThongBao.message"
                        :state="ParamThongBao.state"
                        :title="ParamThongBao.title"
                    />
                </template>
            </PopupVue>

            <PopupVue
                height="auto"
                v-if="ifPopupXacNhanBanVuotSoVeToiDa"
                v-model:dialog="PopupXacNhanBanVuotSoVeToiDa"
                heightScrollView="100%"
                title=""
                :useButtonLeft="false"
                :useButtonRight="false"
                class="popup-padding-16"
            >
                <template #content>
                    <NotificationVue
                        :Params="ParamXacNhanBanVuotSoVeToiDa"
                        @click="XacNhanBanVuotSoVeToiDa"
                    />
                </template>
            </PopupVue>

            <!-- ====================================Toast thành công========================================= -->
            <DxToast
                v-model:visible="toast.visible"
                v-model:type="toast.type"
                :position="{
                    my: 'top center',
                    at: 'top center',
                    offset: '0 15',
                }"
                class="toast-style"
            >
                <template #contentTemplate>
                    <ToastContentVue :toast="toast" />
                </template>
            </DxToast>
        </ion-content>

        <XuLyBanVeVue
            v-if="isXuLy"
            :state="state"
            :model="model"
            :msgError="msgError"
            @thulai="dongPopupXuLyBanVe"
            :thongTinThanhToan="thongTinThanhToan"
        />
    </ion-page>
</template>
<script>
import ViDienTuVue from "../../src/components/ViDienTu";
import XacNhanThanhToanVue from "../../src/components/XacNhanThanhToan";
import GoiYVue from "../components/GoiY";
import PopupVue from "../../src/components/_Common/Popup";
import ThongBaoVue from "../../src/components/_Common/ThongBao";
import ToastContentVue from "../../src/components/_Common/ToastContent";
import DonHang from "../../class/DonHang";
import { ChoNgoi } from "../../class/PhuongTien";
import ChuyenDi from "../../class/ChuyenDi";
import {
    IonHeader,
    IonToolbar,
    IonContent,
    IonPage,
    IonLabel,
    IonInput,
    IonItem,
    IonIcon,
    IonButton,
    IonCheckbox,
    onIonViewWillLeave,
    onIonViewWillEnter,
} from "@ionic/vue";
import { qrCodeOutline } from "ionicons/icons";
import {
    DxNumberBox,
    DxTextBox,
    DxValidationGroup,
    DxValidator,
    DxSelectBox,
    DxToast,
    DxButton,
} from "devextreme-vue";
import {
    DxEmailRule,
    DxNumericRule,
    DxPatternRule,
    DxRequiredRule,
    DxRangeRule,
    DxStringLengthRule,
} from "devextreme-vue/data-grid";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import XuLyBanVeVue from "../components/_Common/XuLyBanVe.vue";
import NotificationVue from "@sonphat/common-v1/components/Notification.vue";

export default {
    components: {
        IonHeader,
        IonToolbar,
        ViDienTuVue,
        XacNhanThanhToanVue,
        PopupVue,
        ThongBaoVue,
        GoiYVue,
        DxValidationGroup,
        DxTextBox,
        DxRequiredRule,
        DxRangeRule,
        DxStringLengthRule,
        DxNumericRule,
        DxPatternRule,
        DxValidator,
        DxEmailRule,
        DxNumberBox,
        DxSelectBox,
        DxToast,
        ToastContentVue,
        IonContent,
        IonPage,
        IonLabel,
        IonInput,
        IonItem,
        IonIcon,
        IonButton,
        IonCheckbox,
        DxButton,
        XuLyBanVeVue,
        NotificationVue,
    },
    setup() {
        return { qrCodeOutline };
    },
    data() {
        return {
            disabledXacNhan: false,
            dialogThongBao: false,
            enabledQuetMa: false,
            LayDanhSachVi: false,
            disabledThuTien: true,
            isValidSoDienThoai: true,
            isValidGiaVe: true,
            khoaCho: false,
            thongTinXacNhanThanhToan: {
                ThucThu: 0,
                GiaVe: 0,
                TongTien: 0,
                ChietKhau: 0,
                HinhThucThanhToan: "",
                SoDienThoai: 0,
                HoTen: "",
                ViTriCho: "",
            },
            model: {
                DiemXuong: null,
                SoDienThoai: "",
                SoLuong: 1,
                HoTen: "",
                Email: "",
                ChoDaChon: "",
                ThucThu: 0,
                GiaVe: 0,
                GiaVeSauGiamTru: 0,
                Vi: null,
            },
            data: {
                ChoDaChon: [],
                DiemXuong: [],
                GoiYDiemXuong: [],
                GoiYGiaVe: [],
            },
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
            phonePattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
            toast: { visible: false, message: "", type: "success" },
            CheckInVe: false,
            maxVe: 5,
            quyenTuyChonPhatHanhVe: false,
            CheckPhatHanhVe: false,
            diemBatDau: {},
            state: "loading",
            isXuLy: false,
            thongTinThanhToan: null,
            msgError: null,
            ifPopupXacNhanBanVuotSoVeToiDa: false,
            PopupXacNhanBanVuotSoVeToiDa: false,
            ParamXacNhanBanVuotSoVeToiDa: {
                state: "Warning",
                title: "Thông báo!",
                message_title: "Bạn đã bán hết số vé tối đa được phép tại chuyến đi này!",
                message: "Bạn có muốn bán vượt số vé tối đa?",
            },
        };
    },
    computed: {
        ...mapState({
            ToaDo: (state) => state.ThongTin.ToaDo,
        }),
        ...mapGetters("Ve", ["chinhSuaSoatVe"]),
        ...mapGetters("QrCode", ["dataQuetMa"]),
        GoiYGiaVe() {
            return this.data.GoiYGiaVe;
        },
        GoiYDiemXuong() {
            return this.data.GoiYDiemXuong;
        },
        ChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            return this.$store.state.DVVTLenh.ChiTietCauHinhDoanhNghiepTheoMaCauHinh;
        },
    },
    methods: {
        ...mapActions("QrCode", ["quetMaQR"]),
        ...mapActions("Ve", [
            "layGoiYDanhSachDiemXuongTheoSoDienThoaiVaChuyenDi",
            "layDSDiemDungTheoLoTrinh",
            "layDSGiaVeGoiYTheoLoTrinh",
        ]),
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        onItemClickDiemXuong(item) {
            if (item.itemData) {
                this.model.DiemXuong = item.itemData.guidDiemXuong;
            }
        },
        onChangeGiaVe(e) {
            if (("" + e.component._parsedValue).length < 9) {
                let data = [],
                    giaGoiY = 0;
                let giaVeHienTai = e.component._parsedValue;
                if (!e.component._parsedValue) {
                    this.data.GoiYGiaVe = data;
                    return;
                }
                if (giaVeHienTai <= 5000) {
                    this.data.GoiYGiaVe = data;
                    return;
                }
                function goiYGia() {
                    let currency = [500000, 100000, 50000, 20000, 10000, 5000];
                    let i = 0;
                    let giaVe = 0;
                    let temp = 0;
                    while (true) {
                        if (i > 50 || temp > giaVeHienTai || !currency[i]) {
                            break; //Thoát khỏi white
                        }
                        if (giaVeHienTai > currency[i]) {
                            temp += currency[i];
                            if (temp > giaVeHienTai) {
                                //Tiếp tục thử với mệnh giá tiếp theo
                                temp = giaVe;
                                i++;
                            }
                        } else {
                            i++;
                        }
                        if (temp <= giaVeHienTai) {
                            //Chốt giá
                            giaVe = temp;
                        }
                    }
                    return giaVe;
                }
                giaGoiY = goiYGia();
                this.LayDsGiaTienGoiY(giaGoiY);
            } else {
                this.data.GoiYGiaVe = [];
            }
        },
        onEnterKey(e) {
            let index = e.event.currentTarget.tabIndex;
            if (index) {
                let ref = this.$refs["refTab" + (index + 1)];
                ref ? ref.instance.focus() : null;
            }
        },
        chonGoiYGiaVe(item) {
            this.model.GiaVe = item.text;
            console.log("🚀 ~", this.taoDonHang());
        },
        async ChonGoiYDiemXuong(item) {
            try {
                this.model.GiaVe = 0;
                this.model.GiaVeSauGiamTru = 0;
                this.model.DiemXuong = item.guidDiemXuong;
                let query = {
                    IdChuyenDi: this.$route.query.guidChuyenDi,
                    IdChoNgoi: "00000000-0000-0000-0000-000000000000",
                    IdDiemXuatPhat: this.diemBatDau.guidDiemXuong,
                    IdDiemKetThuc: item.guidDiemXuong,
                };
                let rs = await this.$store.dispatch(
                    "ChuyenDi/LayGiaVeTheoChoNgoi",
                    query,
                );
                if (rs.status) {
                    this.model.GiaVe = rs.data.giaVeGoc;
                    this.model.GiaVeSauGiamTru = rs.data.giaVeSauGiamTru;
                }
            } catch (error) {
                console.log("🚀 ~  error:", error);
            }
        },
        onKeyUpSoDienThoai() {
            let IsValidSoDienThoai = this.$refs.refValidateSoDienThoai.instance.validate();
            this.isValidSoDienThoai = IsValidSoDienThoai.isValid;
        },
        async onFocusOutSoDienThoai() {
            //Ẩn ghi chú dưới trường sdt
            let IsValidSoDienThoai = this.$refs.refValidateSoDienThoai.instance.validate();
            this.isValidSoDienThoai = IsValidSoDienThoai.isValid;
            if (this.model.SoDienThoai) {
                let rs = await this.layGoiYDanhSachDiemXuongTheoSoDienThoaiVaChuyenDi({
                    guidChuyenDi: this.$route.query.guidChuyenDi,
                    soDienThoai: this.model.SoDienThoai,
                });

                if (rs.Data.data && rs.Data.data.length > 0) {
                    this.LayDanhSachDiemXuongGoiYKhiNhapSDT(rs.Data.data);
                } else {
                    this.data.GoiYDiemXuong = this.data.DiemXuong.map((e) => {
                        e.text = e.tenDiemXuong;
                        e.id = e.guidDiemXuong;
                        return e;
                    });
                }
            } else {
                this.data.GoiYDiemXuong = this.data.DiemXuong.map((e) => {
                    e.text = e.tenDiemXuong;
                    e.id = e.guidDiemXuong;
                    return e;
                });
            }
        },
        LayDanhSachDiemXuongGoiYKhiNhapSDT(arr) {
            this.data.GoiYDiemXuong = arr.map((e) => {
                let data = {
                    text: e.tenDiemDung,
                    guidDiemXuong: e.guidDiemDung,
                    id: e.guidDiemDung,
                };
                return data;
            });
            if (this.data.GoiYDiemXuong.length > 1) {
                let tmpGuidBefore = this.data.GoiYDiemXuong[0].guidDiemXuong;
                let tmpGuidAfter = this.data.GoiYDiemXuong[1].guidDiemXuong;
                let tmpIndexBefore = this.data.DiemXuong.indexOf(
                    this.data.DiemXuong.filter(
                        (e) => e.guidDiemXuong == tmpGuidBefore,
                    )[0],
                );
                let tmpIndexAfter = this.data.DiemXuong.indexOf(
                    this.data.DiemXuong.filter((e) => e.guidDiemXuong == tmpGuidAfter)[0],
                );
                if (tmpIndexBefore > tmpIndexAfter) {
                    this.data.GoiYDiemXuong.reverse();
                }
            } else if (this.data.GoiYDiemXuong.length == 1) {
                let tmpGuid = this.data.GoiYDiemXuong[0].guidDiemXuong;
                let itemBefore = null;
                let itemAfter = null;
                let tmpIndex = this.data.DiemXuong.indexOf(
                    this.data.DiemXuong.filter((e) => e.guidDiemXuong == tmpGuid)[0],
                );

                if (tmpIndex > 0) {
                    itemBefore = this.data.DiemXuong[tmpIndex - 1];
                    itemBefore.text = itemBefore.tenDiemXuong;
                    itemBefore.id = itemBefore.guidDiemXuong;
                    this.data.GoiYDiemXuong.unshift(itemBefore);
                }
                if (tmpIndex + 1 < this.data.DiemXuong.length) {
                    itemAfter = this.data.DiemXuong[tmpIndex + 1];
                    itemAfter.text = itemAfter.tenDiemXuong;
                    itemAfter.id = itemAfter.guidDiemXuong;
                    this.data.GoiYDiemXuong.push(itemAfter);
                }
            }
        },
        async onFocusOutGiaVe() {
            //Ẩn ghi chú dưới trường giá vé
            let IsValidGiaVe = this.$refs.refValidateGiaVe.instance.validate();
            this.isValidGiaVe = IsValidGiaVe.isValid;
        },
        async thuTien() {
            this.disabledXacNhan = true;
            let rs = this.$refs.formValidation.instance.validate();
            if (rs.isValid) {
                if (this.model.Vi) {
                    //set data thông tin xác nhận thanh toán

                    let rsKiemTraChuyenDi = await this.$store.dispatch(
                        "ChuyenDi/KiemTraTrangThaiChuyenDi",
                        {
                            IdChuyenDi: this.$route.query.guidChuyenDi,
                            SoKhach: this.model.SoLuong,
                        },
                    );
                    if (!rsKiemTraChuyenDi.data) {
                        this.disabledXacNhan = false;
                        if (
                            this.$Helper.KiemTraQuyen(
                                this.$t("QuyenBanVe.BanVeTaiBen"),
                                this.$t("QuyenBanVe.BanVeVuotSoVeToiDa"),
                            ) &&
                            rsKiemTraChuyenDi.errorCode == 1268
                        ) {
                            this.showPopup("PopupXacNhanBanVuotSoVeToiDa");
                            return;
                        }
                        this.$Helper.ThongBaoPopup({
                            type: "Error",
                            message_title: "Bán vé gặp lỗi!",
                            message: rsKiemTraChuyenDi.message,
                        });
                        return;
                    }

                    this.dongXacNhanThanhToan();
                }
            } else {
                this.disabledXacNhan = false;
                this.$Helper.autoFocusError(rs);
            }
        },
        XacNhanBanVuotSoVeToiDa(bool) {
            if (bool == true) {
                this.dongXacNhanThanhToan();
                this.PopupXacNhanBanVuotSoVeToiDa = false;
            } else if (bool == false) {
                this.PopupXacNhanBanVuotSoVeToiDa = false;
            }
        },
        taoDonHang() {
            let donHang = new DonHang();
            donHang.hoTen = this.model.HoTen;
            donHang.soDienThoai = this.model.SoDienThoai;
            donHang.email = this.model.Email;
            donHang.chuyenDiId = this.$route.query.guidChuyenDi;
            donHang.donGia = this.model.GiaVe;
            return donHang;
        },
        dongXacNhanThanhToan() {
            //Thanh toán theo
            if (!this.model.Vi.id) {
                this.thucHienThanhToanTienMat();
            } else {
                let DiemXuong =
                    this.data.DiemXuong.filter(
                        (x) => x.guidDiemXuong == this.model.DiemXuong,
                    )[0] || {};
                let type =
                    this.$route.query.KhongCoSoDoGhe == "true"
                        ? this.$t("StringCheck.KhongCoSoDoGhe")
                        : this.$t("StringCheck.GhePhu");
                //=========================Chuyển sang trang thanh toán QR=========================
                this.$router.push({
                    path: "thanh-toan-bang-qrcode",
                    query: {
                        idVi: this.model.Vi.id,
                        guidChuyenDi: this.$route.query.guidChuyenDi,
                        guidDoanhNghiep: this.$route.query.guidDoanhNghiep,
                        hoTen: this.model.HoTen,
                        soDienThoai: this.model.SoDienThoai,
                        tenVi: this.model.Vi.tenVi,
                        giaVe: this.model.GiaVe,
                        phatHanhVe: this.CheckInVe,
                        maDiemXuong: DiemXuong.guidDiemXuong || null,
                        tenDiemXuong: DiemXuong.tenDiemXuong || null,
                        type: type,
                        soLuong: this.model.SoLuong,
                    },
                });
            }
        },
        async thucHienThanhToanTienMat() {
            let donHang = new DonHang();
            let DiemXuong = this.data.DiemXuong.filter(
                (x) => x.guidDiemXuong == this.model.DiemXuong,
            )[0];
            //Khởi tạo thanh toán tiền mặt
            try {
                this.isXuLy = true;
                this.state = "loading";
                let rsThanhToan;
                if (this.$route.query.KhongCoSoDoGhe == "true") {
                    rsThanhToan = await donHang.banVeKhongCoSoDoGhe(
                        this.$route.query.guidChuyenDi,
                        this.model.GiaVe,
                        this.model.GiaVeSauGiamTru,
                        this.model.SoDienThoai,
                        this.model.SoLuong,
                        this.CheckPhatHanhVe,
                        DiemXuong.guidDiemXuong,
                        DiemXuong.tenDiemXuong,
                        this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                    );
                } else {
                    rsThanhToan = await donHang.banVeGhePhu(
                        this.$route.query.guidChuyenDi,
                        this.model.GiaVe,
                        this.model.GiaVeSauGiamTru,
                        this.model.SoDienThoai,
                        this.model.SoLuong,
                        this.CheckPhatHanhVe,
                        DiemXuong.guidDiemXuong,
                        DiemXuong.tenDiemXuong,
                        this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                    );
                }
                if (!rsThanhToan) {
                    this.disabledXacNhan = false;
                    this.state = "error";
                    this.msgError = "Lỗi hệ thống!";
                    throw new Error("Lỗi");
                }
                if (!rsThanhToan.Data.status) {
                    // this.dialogXacNhanThanhToan = false;
                    this.disabledXacNhan = false;
                    this.state = "error";
                    this.msgError = rsThanhToan.Data.message;
                    return;
                }
                // if (this.CheckInVe) {
                //     this.layThongTinVe(rsThanhToan.Data.data);
                // }
                this.model.MaGiaoDich = rsThanhToan.Data.data;
                this.maGiaoDich = rsThanhToan.Data.data;
                if (this.CheckInVe == true) {
                    this.state = "inVe";
                } else {
                    this.state = "success";
                }
                // this.toastThanhCong("Bán vé thành công!");
                // setTimeout(() => {
                //     this.$router.push({
                //         path: "/tabs/tab3",
                //     });
                // }, 1000);
            } catch (error) {
                this.disabledXacNhan = false;
                console.log("🚀 ~ thucHienThanhToanTienMat ~ error", error);
                this.state = "error";
                this.msgError = "Lỗi hệ thống!";
            }
        },
        async layThongTinVe(maGiaoDich) {
            try {
                let rs = await this.$AppClass.InVe(
                    this.$i18n.t("url.InVe"),
                    "MaGiaoDich",
                    maGiaoDich,
                );
                if (rs == "error") {
                    return this.thongBaoLoi("error", "In thất bại! ");
                }
                this.$stopLoading;
            } catch (error) {
                this.$stopLoading;
                this.error = true;
                console.error(error);
            }
        },
        thongBao(state, title, message) {
            this.ParamThongBao = {
                state: state,
                title: title,
                message: message,
            };
            this.dialogThongBao = true;
        },
        thongBaoLoi(message, log) {
            this.thongBao("Error", message || "Đã xảy ra lỗi. Vui lòng thử lại");
            console.log(
                "%c log ",
                "color: white; background-color: #95B46A",
                "=========>",
                log,
            );
        },
        toastThanhCong(message) {
            this.toast = {
                message: message,
                type: "success",
                visible: true,
            };
        },
        LayDsGiaTienGoiY(giaGoiY) {
            let data = [];
            let giaGoiYThap = giaGoiY;
            let giaGoiYCao = giaGoiY;
            for (let i = 0; i < 5; i++) {
                if (giaGoiYThap < 10000) {
                    break;
                }
                let item = {
                    id: i,
                    active: false,
                };
                if (i < 3) {
                    item.value = giaGoiYCao;
                    item.text = this.$MoneyFormat(giaGoiYCao) + "đ";
                    data.push(item);
                    giaGoiYCao = giaGoiYCao + 5000;
                } else {
                    giaGoiYThap = giaGoiYThap - 5000;
                    item.value = giaGoiYThap;
                    item.text = this.$MoneyFormat(giaGoiYThap) + "đ";
                    data.unshift(item);
                }
            }
            this.data.GoiYGiaVe = data;
        },
        closeAllDialog() {
            this.dialogThongBao = false;
        },
        async initPage() {
            this.LayDanhSachVi = true;
            this.model.DiemXuong = null;
            this.model.GiaVe = 0;
            this.model.GiaVeSauGiamTru = 0;
            this.model.SoLuong = 1;
            this.model.soDienThoai = null;
            try {
                this.$startLoading;
                this.diemBatDau = {};
                let cauHinhChung = JSON.parse(
                    localStorage.getItem("ThongTinCauHinhChung"),
                );
                this.maxVe = cauHinhChung.soChoToiDa;
                let rsDiemDung = await this.layDSDiemDungTheoLoTrinh(
                    this.$route.query.guidLoTrinh,
                );

                // let rsGiaVe = await this.layDSGiaVeGoiYTheoLoTrinh(
                //     this.$route.query.guidLoTrinh,
                // );
                if (rsDiemDung.StatusCode == 200) {
                    this.data.DiemXuong = rsDiemDung.Data.data.filter(
                        (e) => !e.laDiemBatDau,
                    );
                    if (this.data.DiemXuong.length > 0) {
                        let itemDiemXuongMacDinh = rsDiemDung.Data.data.find(
                            (e) => e.laDiemKetThuc,
                        );
                        this.diemBatDau = rsDiemDung.Data.data.find(
                            (e) => e.laDiemBatDau,
                        );
                        this.model.DiemXuong = itemDiemXuongMacDinh.guidDiemXuong;
                        this.ChonGoiYDiemXuong(itemDiemXuongMacDinh);
                    }
                    this.data.GoiYDiemXuong = this.data.DiemXuong.map((e) => {
                        e.text = e.tenDiemXuong;
                        e.id = e.guidDiemXuong;
                        return e;
                    });
                }
                // if (rsGiaVe.status) {
                //     this.data.GoiYGiaVe = rsGiaVe.data.map((e) => {
                //         e.text = e.giaVe;
                //         e.id = e.guidDonGia;
                //         return e;
                //     });
                //     if (rsGiaVe.data.length > 0) {
                //         this.model.GiaVe = rsGiaVe.data[0].giaVe;
                //     }
                // }
                this.$stopLoading;
            } catch (error) {
                console.log(
                    "🚀 ~ file: ThongTinSoatVe.vue ~ line 280 ~ mounted ~ error",
                    error,
                );
            }
            //Xóa ChoDaChon để fix lỗi quay lại không lấy dc dữ liệu
            // localStorage.removeItem("ChoDaChon");
            let refSoLuong = this.$refs.refSoLuong.instance;
            if (refSoLuong) {
                setTimeout(() => {
                    refSoLuong.focus();
                }, 100);
            }
            //set Data
            // this.LayDsGiaTienGoiY(this.model.GiaVe);
            this.LayDanhSachVi = false;
        },
        resetData() {
            this.model = {
                DiemXuong: null,
                SoDienThoai: "",
                SoLuong: 1,
                HoTen: "",
                Email: "",
                ChoDaChon: "",
                ThucThu: 0,
                GiaVe: 0,
                GiaVeSauGiamTru: 0,
                Vi: null,
            };
            this.$refs.formValidation.instance.reset();
        },
        checkQuyenPhatHanhVe() {
            this.quyenTuyChonPhatHanhVe = this.$Helper.KiemTraQuyen(
                this.$t("QuyenLenhDienTu.BanVeTaiBen"),
                this.$t("QuyenLenhDienTu.TuyChonPhatHanhVe"),
            );
            let CheckRolePhatHanhVe = this.$Helper.KiemTraQuyen(
                this.$t("QuyenLenhDienTu.BanVeTaiBen"),
                this.$t("QuyenLenhDienTu.BatBuocPhatHanhVe"),
            );
            if (!this.quyenTuyChonPhatHanhVe) {
                this.CheckPhatHanhVe = CheckRolePhatHanhVe;
            } else {
                if (CheckRolePhatHanhVe) {
                    this.CheckPhatHanhVe = true;
                    this.quyenTuyChonPhatHanhVe = false;
                } else {
                    this.CheckPhatHanhVe = localStorage.getItem("CheckPhatHanhVe")
                        ? JSON.parse(localStorage.getItem("CheckPhatHanhVe"))
                        : false;
                }
            }
        },
        dongPopupXuLyBanVe() {
            this.isXuLy = false;
        },
        async LayChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            try {
                this.$startLoading;
                await this.$store.dispatch(
                    "DVVTLenh/LayChiTietCauHinhDoanhNghiepTheoMaCauHinh",
                    {
                        DanhSachMaCauHinhCanLay: [
                            this.$t(
                                "MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru",
                            ),
                        ],
                        IdDoanhNghiep: this.$route.query.guidDoanhNghiep,
                    },
                );
            } catch (error) {
                console.log("🚀 ~ error:", error);
            } finally {
                this.$stopLoading;
            }
        },
    },
    created() {
        onIonViewWillEnter(() => {
            this.disabledXacNhan = false;
            this.enabledQuetMa = true;
            this.state = "loading";
            this.isXuLy = false;
            setTimeout(() => {
                this.initPage();
            }, 500);
            this.checkQuyenPhatHanhVe();
            this.LayChiTietCauHinhDoanhNghiepTheoMaCauHinh();
        });
    },
    async mounted() {
        onIonViewWillLeave(() => {
            this.resetData();
            this.closeAllDialog();
            this.enabledQuetMa = false;
        });
    },
    watch: {
        dangQuetMa() {
            if (this.enabledQuetMa) {
                let sdt = this.dataQuetMa.split("<<");
                let checkQR = false;
                let checkPcCovid = this.dataQuetMa.indexOf("<<") != -1;
                if (sdt && Array.isArray(sdt) && sdt.length > 0) {
                    if (sdt && Array.isArray(sdt) && sdt.length > 0) {
                        if (!parseInt(sdt[1])) {
                            checkQR = false;
                        } else {
                            checkQR = true;
                        }
                    } else {
                        checkPcCovid = false;
                    }
                }
                if (!checkPcCovid || !checkQR) {
                    let toast = {
                        message: !checkPcCovid
                            ? "Mã QR không hợp lệ!"
                            : !checkQR
                            ? "Vui lòng bỏ ẩn QR!"
                            : "",
                        type: "error",
                        visible: true,
                    };
                    this.emitter.emit("onToast", toast);
                    return;
                }
                this.model.SoDienThoai = sdt[1];
            }
        },
        "model.DiemXuong": {
            handler: async function (val) {
                // if (val) {
                //     let query = this.$route.query;
                //     let rs = await new ChuyenDi().layGiaTienGoiYTheoDiemXuong(
                //         query.guidChuyenDi,
                //         query.GuidChoNgoi,
                //         val
                //     );
                //     if (rs.Data.status == true) {
                //         this.model.GiaVe = rs.Data.data;
                //         this.LayDsGiaTienGoiY(this.model.GiaVe);
                //     }
                // }
            },
            deep: true,
        },
        PopupXacNhanBanVuotSoVeToiDa() {
            if (!this.PopupXacNhanBanVuotSoVeToiDa) {
                setTimeout(() => {
                    this.ifPopupXacNhanBanVuotSoVeToiDa = false;
                }, 300);
            }
        },
    },
};
</script>
